.header{
    height: '100%';
    padding-top: 10%;
    padding-bottom: 5%;
    background: white;
    border-radius: 0rem 0rem 4rem 4rem;
}
.carousel{
    height: 100%;
}

.imagemember{
    border-radius: 150px;
    height: 35;
    width: 45%;
}
.expand-up-icon{
    float: right;
}
.row{
    justify-content: center;
}
.option-title{
    border-bottom: 1px solid;
    margin-bottom: 6%;
}
.team-title{
    border-color: hotpink;
}
.teamicon{
    color: hotpink;
}
.tratment-title{
    border-color: lightsalmon;
}
.tratmenticon{
    color: lightsalmon
}
.tarifa-title{
    border-color: #BD82DE;
}
.tarifaicon{
    color: #BD82DE;
}
.clinica-title{
    border-color: aqua;
}
.clinica-icon{
    color: aqua;
}
.formacion-title{
    border-color: rgb(0, 255, 179);
}
.formacionicon{
    color: rgb(0, 255, 179);
}
.contacto-title{
    border-color:  khaki;
}
.contactoicon{
    color: khaki;
}
.contactocard h3{
    font-family: 'cobertwide';
}
.infomember h3{
    font-family: 'cobertwide';
}
.infomember h4{
    font-family: 'flaunters';
}
.infomember p{
    font-family: 'Lucida Sans';
}
.inicio-text p{
    font-family: 'caviar';
    font-size: 16px;
    text-align: justify;
    font-weight: normal;
    color: black;
    padding: 10px 5px;
    margin:5px;
}

.contactos-page {
background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(250,250,250,1) 74%);
}
.tlf{
    font-size: 20px;
    color: black;
}

.mail{
    font-size: 15px;
    color: black;
}

.map-container{
    background: red;
}

a:link{
text-decoration:none;
}
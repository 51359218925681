.navbar-nav > li{
    padding: 0 10px
  }
.text-redes{
    color:  rgb(43, 173, 173);
    font-weight: bold;
    font-family: 'bebas';
}

.nav-link{
    color: rgb(43, 173, 173); 
}

.nav-link:hover{
    color: #616363;
}

@media only screen and (min-width: 500px) {
    .text-link{
        font-size: 20px !important;
    }
}
@media only screen and (min-width: 700px) {
    .text-link{
        font-size: 30px !important;
    }
}
@media only screen and (min-width: 900px) {
    .text-link{
        font-size: 40px !important;
    }
}
@media only screen and (min-width: 1200px) {
    .text-link{
        font-size: 60px !important;
    }
}

.text-link{
    font-weight: bold;
    font-family: 'moviebold';
    margin: 0px;
}
.logo-link{
    margin-right: 10px;
}
.logo-link:hover{
    border:2px solid  rgb(43, 173, 173);
    border-radius: 150px;
    background:linear-gradient(191deg, rgba(136,214,201,1) 0%, rgba(222,222,224,1) 80%);
    opacity: 0.8;
}
.navbar{
    background: white;
    opacity: 0.95;
}

.tablet-size{
    column-count: 2;
}

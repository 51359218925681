html,
body {
  min-height: 100%;
  height: 100%; }

html {
  font-size: 16px; }

body {
  position: relative;
  font-size: 100%; }

.gallery-container {
  padding-top: .9375rem; }

.gallery-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 1.875rem; }

.gallery-thumbnail {
  max-width: 100%;
  height: auto;
  border-radius: 4px; }

.card-icon-open {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out; }
  .card-icon-open:focus, .card-icon-open:hover {
    color: #111; }

.gallery-thumbnail:focus ~ .card-icon-open,
.gallery-thumbnail:hover ~ .card-icon-open,
.gallery-thumbnail ~ .card-icon-open:focus,
.gallery-thumbnail ~ .card-icon-open:hover {
  opacity: 1; }

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, 0.75); }

.modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  padding: 0;
  overflow: auto;
  border-radius: 4px;
  transform: translate(-50%, -50%); }

.modal-close {
  position: absolute;
  top: 0;
  right: 8px;
  font-size: 2rem;
  color: #111;
  transition: all 0.25s ease-in-out; }
  .modal-close:focus, .modal-close:hover {
    color: #fff; }
.formacion p {
        font-family: 'caviar';
        font-size: 25px;
        color: black;
        text-align: center;
    }

.frparallax {
    height: 300px; 
    width: 500px;
    background-attachment: fixed;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 25% 35%;    
  }
.frparallaxlarge {
    height: 300px; 
    width: 600px;
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 35% 35%;    
  }
.frprxlogo {
    background-image: url('../../images/logo_szience.PNG');
  }
.frprx1 {
    background-image: url('../../images/formacion/formacion1.JPG');
  }
.frprx2 {
    background-image: url('../../images/formacion/formacion6.jpeg');
  }
.frprx3 {
    background-image: url('../../images/formacion/formacion3.JPG');
  }
.frprx4 {
    background-image: url('../../images/formacion/formacion4.JPG');
  }
.frprx5 {
    background-image: url('../../images/formacion/formacion5.JPG');
  }
.frprx6 {
    background-image: url('../../images/formacion/formacion2.JPG');
  }

.nav-scienze a{
    color: rgb(46, 42, 32);
}
.nav-scienze a:hover{
    color: gainsboro;
}


.card{
    display: block;
    justify-content: center;
    padding: 1rem;
    margin-top: 5%;
}
.equipo-page {
    height: 100%;
    background: transparent;
    padding: 3rem 1rem;
    /* border: 2px solid #ccc; */
    /* IMPORTANTE */
    text-align: center;
    display: block;
    justify-content: center;
  }
  .containerForma{
    /* overflow: hidden; */
    position: absolute;
    height: 100%;
    width: 100%;
    /* z-index: 1; */
    /* display: block; */
    /* vertical-align: baseline; */
  }

  .containerForma h4 {
    text-align: center;
    width: 100%;
  }

  @keyframes floating{
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 100px); }
    to   { transform: translate(0, 0px); }   
  }
  @keyframes floatingInverse{
    from { transform: translate(-35px,  0px); }
    50%  { transform: translate(35px, 0px); } 
    to   { transform: translate(-35px, 0px); }   
  }
  

  .fondoForma3{
    height: 100%;
    width: 100%;
    /* animation: floating 4s ease-in-out infinite; */
    background-position: bottom 25px center;
    background-repeat: no-repeat;
    background-size: 1700px;
    background-image: url('../../images/equipo/equipofullmid.png');
  }
  .card-img-top {
    width:40%;
    height:40%;
    border-radius:150px;
  }
  .card-title{
    font-family: 'cobertwide';
    margin-bottom: 3rem;
    font-weight: bold;
    color:rgb(43, 173, 173);
    font-size: x-large;
  }

  .card-body h4{
    color:rgb(38, 44, 54);
    font-family: 'flaunters';
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 20px;
  }
  .card-body p{
    font-size: larger;
    text-justify: newspaper;
    padding: 0;
    font-family: 'caviar';
  }
.results-page {
    padding: 3rem 1rem;
    text-align: center;
}

.card {
    width: 345px;
    
}
.card-container:hover{
    margin-top: -0.5rem;
    margin-bottom: +0.5rem;
}

.card-container {
    display: inline-block;
    justify-content: center;
    padding: 1rem;
    transition:0.3s;
}

.card-media {
    height: 140px;
}

.page-message {
    text-align: center;
}

.typography{
    text-align: center;
}
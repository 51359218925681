.details-page {
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.details-page hr {
    background-color: rgb(43, 173, 173);
}
.paper-container {
    padding: 1rem;
    width: fit-content;
}

.content {
    padding: 1rem 0rem;
    width: 75%;
}

/* .item-image {
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
} */

.item-loader {
    padding: 1rem;
}

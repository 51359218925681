@font-face{
  font-family: 'moviebold';
  src: url('../styles/SF\ Movie\ Poster\ Bold.ttf');
}
@font-face{
  font-family: 'movie';
  src: url('../styles/Bebas-Regular.ttf');
}
@font-face{
  font-family: 'bebas';
  src: url('../styles/Bebas-Regular.ttf');
}
@font-face{
  font-family: 'arca';
  src: url('../styles/ArcaMajora3-Bold.otf');
}
@font-face{
  font-family: 'borisbb';
  src: url('../styles/BorisBlackBloxx.ttf');
}
@font-face{
  font-family: 'cobertwide';
  src: url('../styles/CorbertWide-Regular.ttf');
}
@font-face{
  font-family: 'flaunters';
  src: url('../styles/Flaunters.otf');
}
@font-face{
  font-family: 'caviar';
  src: url('../styles/CaviarDreams.ttf');
}
@font-face{
  font-family: 'luisbold';
  src: url('../styles/Louis\ George\ Cafe\ Bold.ttf');
}
@font-face{
  font-family: 'luislight';
  src: url('Louis\ George\ Cafe\ Light.ttf');
}
@font-face{
  font-family: 'quick';
  src: url('Quicksand_Light.otf');
}
.fixed-indice {
    position: fixed;
    min-height: 120px;
    width: 300px;
    text-align: center;
    word-wrap: break-word; 
}

.content {
    float: left;
    position: relative;
}
p {
    text-align: justify;
    font-size: medium;
    padding-bottom: 2rem;
  }

h1 {
    text-align:center;
    padding-bottom: 2rem;
}
.panel-color {
    background: linear-gradient(to bottom right, #ffffff 25%, rgb(43, 173, 173) 90%);
    /* background: transparent; */
}

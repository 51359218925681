
.inicio-page {
    padding: 3rem 1rem;
    display: grid;
    justify-content: center;
    align-items: center; 
}
.carousel-container{
    height: 400px;
    width: 100%;
}
.carousel-item{
    height: 400px;
    width: 100%;
}

.title{
    text-align: center;
    padding: 1rem;
}
.paper-container {
    padding: 1rem;
    width: fit-content;
}

.content {
    padding: 1rem 0rem;
    width: 75%;
}

.content-padre{
    text-align: left;
    padding: 1rem 1rem;
    display: flex;
    justify-content: left;
}
.item-image {
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.item-loader {
    padding: 1rem;
}

.panel-inicio{
    background-color: white;
}
.my-font{
    font-family: 'bebas';
    font-size: 35px;
}
.bold-text{
    font-weight: bold;
}
.trato{
background-color: rgb(43, 173, 173);
color: white;
}

.preocupados{
background-color: white;
color: rgb(43, 173, 173);
}

/* .inicio-text{
    font-family:'caviar';
    font-size: '35px';
    text-align:'center';
    margin-top: 2rem;
    margin-left: 2rem;
    color: black;
} */

/* .inicio-text p {
    color: yellow;
    font-size: 50px;
} */

.parallax {
    height: 300px; 
    width: 500px;
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 25% 35%;    
  }
.prx1 {
    background-image: url('../../images/clinica/letrero.jpeg');
  }
.prx2 {
    background-image: url('../../images/clinica/mesa.jpeg'); 
  }

.inicio-text p span{
    font-weight: bold;
    color: rgb(43, 173, 173);
}

.fexible-cards{
    background-image: url('../../images/green-1846861_1280.png');
    background-size: 70%;
    background-position-x: 50% ;    
}
@keyframes floatingInverse{
    from { transform: translate(-35px,  0px); }
    50%  { transform: translate(35px, 0px); } 
    to   { transform: translate(-35px, 0px); }   
  }
/* .cardtitlehidden{
    animation: floatingInverse 5s infinite;
} */





